import '../App.css';
import React, { useEffect } from 'react'
import * as lang from "./LandingPage.json"

export default function LandingPage() {
    let data = JSON.parse(JSON.stringify(lang));
    
    useEffect(() =>{
        document.title = "Rafael Azevedo"
    }, [])

    return (
        <div>
            <header className="py-5">
                <div className="container">
                    <div className="row">
                        <div className="col-md-8 offset-md-2 d-flex justify-content-between" style={{ alignItems: "center" }}>
                            <div>
                                <ul className='list-style'>
                                    <li className='list-style-item'>
                                        <a href="/" className='list-style-item-inner special-link' style={{ marginLeft: "-10px" }}>
                                            About me
                                        </a>
                                    </li>
                                    <li className='list-style-item'>
                                        <a href="/projects" className='list-style-item-inner nohyperlink'>
                                            Projects
                                        </a>
                                    </li>
                                    <li className='list-style-item'>
                                        <a href="/widgets" className='list-style-item-inner nohyperlink'>
                                            Widgets
                                        </a>
                                    </li>
                                </ul>
                            </div>
                            <div>
                                <svg style={{display: "none", cursor: "pointer"}} height={24} width={24} id="pt" enable-background="new 0 0 512 512" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"><path d="m0 256c0 110.07 69.472 203.905 166.955 240.076l22.262-240.077-22.262-240.076c-97.483 36.172-166.955 130.006-166.955 240.077z" fill="#6da544"/><path d="m512 256c0-141.384-114.616-256-256-256-31.314 0-61.311 5.633-89.045 15.923v480.154c27.734 10.291 57.731 15.923 89.045 15.923 141.384 0 256-114.616 256-256z" fill="#d80027"/><circle cx="166.957" cy="256" fill="#ffda44" r="89.043"/><path d="m116.87 211.478v55.652c0 27.662 22.424 50.087 50.087 50.087s50.087-22.424 50.087-50.087v-55.652z" fill="#d80027"/><path d="m166.957 283.826c-9.206 0-16.696-7.49-16.696-16.696v-22.26h33.391v22.261c0 9.205-7.49 16.695-16.695 16.695z" fill="#f0f0f0"/><g/><g/><g/><g/><g/><g/><g/><g/><g/><g/><g/><g/><g/><g/><g/></svg>
                                <svg style={{display: "none", marginLeft: "5px", cursor: "pointer"}} height={24} width={24} id="en" enable-background="new 0 0 512 512" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"><circle cx="256" cy="256" fill="#f0f0f0" r="256"/><g fill="#0052b4"><path d="m52.92 100.142c-20.109 26.163-35.272 56.318-44.101 89.077h133.178z"/><path d="m503.181 189.219c-8.829-32.758-23.993-62.913-44.101-89.076l-89.075 89.076z"/><path d="m8.819 322.784c8.83 32.758 23.993 62.913 44.101 89.075l89.074-89.075z"/><path d="m411.858 52.921c-26.163-20.109-56.317-35.272-89.076-44.102v133.177z"/><path d="m100.142 459.079c26.163 20.109 56.318 35.272 89.076 44.102v-133.176z"/><path d="m189.217 8.819c-32.758 8.83-62.913 23.993-89.075 44.101l89.075 89.075z"/><path d="m322.783 503.181c32.758-8.83 62.913-23.993 89.075-44.101l-89.075-89.075z"/><path d="m370.005 322.784 89.075 89.076c20.108-26.162 35.272-56.318 44.101-89.076z"/></g><g fill="#d80027"><path d="m509.833 222.609h-220.44-.001v-220.442c-10.931-1.423-22.075-2.167-33.392-2.167-11.319 0-22.461.744-33.391 2.167v220.44.001h-220.442c-1.423 10.931-2.167 22.075-2.167 33.392 0 11.319.744 22.461 2.167 33.391h220.44.001v220.442c10.931 1.423 22.073 2.167 33.392 2.167 11.317 0 22.461-.743 33.391-2.167v-220.44-.001h220.442c1.423-10.931 2.167-22.073 2.167-33.392 0-11.317-.744-22.461-2.167-33.391z"/><path d="m322.783 322.784 114.236 114.236c5.254-5.252 10.266-10.743 15.048-16.435l-97.802-97.802h-31.482z"/><path d="m189.217 322.784h-.002l-114.235 114.235c5.252 5.254 10.743 10.266 16.435 15.048l97.802-97.804z"/><path d="m189.217 189.219v-.002l-114.236-114.237c-5.254 5.252-10.266 10.743-15.048 16.435l97.803 97.803h31.481z"/><path d="m322.783 189.219 114.237-114.238c-5.252-5.254-10.743-10.266-16.435-15.047l-97.802 97.803z"/></g><g/><g/><g/><g/><g/><g/><g/><g/><g/><g/><g/><g/><g/><g/><g/></svg>
                            </div>
                        </div>

                        
                        <div className="col-md-8 offset-md-2 py-5">
                            <h1>Hi, I'm <span className="my-name">Rafael</span>!</h1>
                            <p className="lead">Learning new things everyday.</p>
                            <div className="d-flex">
                                <a className="nohyperlink" href="https://www.linkedin.com/in/rafael-azevedo-93b048338/" target="_blank">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-linkedin" viewBox="0 0 16 16">
                                        <path d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854zm4.943 12.248V6.169H2.542v7.225zm-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248S2.4 3.226 2.4 3.934c0 .694.521 1.248 1.327 1.248zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016l.016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225z" />
                                    </svg>
                                </a>
                                <div className="empty-div-separador">
                                    &nbsp;&nbsp;
                                </div>
                                <a className="nohyperlink" href="https://instagram.com/rafaelvlazevedo" target="_blank">
                                    <svg className="clickable bi bi-instagram" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
                                        <path d="M8 0C5.829 0 5.556.01 4.703.048 3.85.088 3.269.222 2.76.42a3.9 3.9 0 0 0-1.417.923A3.9 3.9 0 0 0 .42 2.76C.222 3.268.087 3.85.048 4.7.01 5.555 0 5.827 0 8.001c0 2.172.01 2.444.048 3.297.04.852.174 1.433.372 1.942.205.526.478.972.923 1.417.444.445.89.719 1.416.923.51.198 1.09.333 1.942.372C5.555 15.99 5.827 16 8 16s2.444-.01 3.298-.048c.851-.04 1.434-.174 1.943-.372a3.9 3.9 0 0 0 1.416-.923c.445-.445.718-.891.923-1.417.197-.509.332-1.09.372-1.942C15.99 10.445 16 10.173 16 8s-.01-2.445-.048-3.299c-.04-.851-.175-1.433-.372-1.941a3.9 3.9 0 0 0-.923-1.417A3.9 3.9 0 0 0 13.24.42c-.51-.198-1.092-.333-1.943-.372C10.443.01 10.172 0 7.998 0zm-.717 1.442h.718c2.136 0 2.389.007 3.232.046.78.035 1.204.166 1.486.275.373.145.64.319.92.599s.453.546.598.92c.11.281.24.705.275 1.485.039.843.047 1.096.047 3.231s-.008 2.389-.047 3.232c-.035.78-.166 1.203-.275 1.485a2.5 2.5 0 0 1-.599.919c-.28.28-.546.453-.92.598-.28.11-.704.24-1.485.276-.843.038-1.096.047-3.232.047s-2.39-.009-3.233-.047c-.78-.036-1.203-.166-1.485-.276a2.5 2.5 0 0 1-.92-.598 2.5 2.5 0 0 1-.6-.92c-.109-.281-.24-.705-.275-1.485-.038-.843-.046-1.096-.046-3.233s.008-2.388.046-3.231c.036-.78.166-1.204.276-1.486.145-.373.319-.64.599-.92s.546-.453.92-.598c.282-.11.705-.24 1.485-.276.738-.034 1.024-.044 2.515-.045zm4.988 1.328a.96.96 0 1 0 0 1.92.96.96 0 0 0 0-1.92m-4.27 1.122a4.109 4.109 0 1 0 0 8.217 4.109 4.109 0 0 0 0-8.217m0 1.441a2.667 2.667 0 1 1 0 5.334 2.667 2.667 0 0 1 0-5.334" />
                                    </svg>
                                </a>
                                <div className="empty-div-separador">
                                    &nbsp;&nbsp;
                                </div>
                                <a className="nohyperlink" href="https://github.com/Ralxm" target="_blank">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-github" viewBox="0 0 16 16">
                                        <path d="M8 0C3.58 0 0 3.58 0 8c0 3.54 2.29 6.53 5.47 7.59.4.07.55-.17.55-.38 0-.19-.01-.82-.01-1.49-2.01.37-2.53-.49-2.69-.94-.09-.23-.48-.94-.82-1.13-.28-.15-.68-.52-.01-.53.63-.01 1.08.58 1.23.82.72 1.21 1.87.87 2.33.66.07-.52.28-.87.51-1.07-1.78-.2-3.64-.89-3.64-3.95 0-.87.31-1.59.82-2.15-.08-.2-.36-1.02.08-2.12 0 0 .67-.21 2.2.82.64-.18 1.32-.27 2-.27s1.36.09 2 .27c1.53-1.04 2.2-.82 2.2-.82.44 1.1.16 1.92.08 2.12.51.56.82 1.27.82 2.15 0 3.07-1.87 3.75-3.65 3.95.29.25.54.73.54 1.48 0 1.07-.01 1.93-.01 2.2 0 .21.15.46.55.38A8.01 8.01 0 0 0 16 8c0-4.42-3.58-8-8-8" />
                                    </svg>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </header>

            <section id="about" className="py-5">
                <div className="container">
                    <div className="row">
                        <div className="col-md-8 offset-md-2">
                            <h2 className="section-title">About Me</h2>
                            <p>
                                Hello, my name is <a className="special-link">Rafael Azevedo</a>. I'm a twenty one year old computer science student closing on finishing my degree.
                                <br></br>
                                Over the last three years I discovered a huge passion for <a className="special-link">web programming</a> and <a className="special-link">embedded systems</a>. This passion allowed me to develop a website for a class, given by a company, and, on my free time, work on fun projects with an Arduino.
                            </p>
                            <p>
                                Apart from programming, I am also passionate about music. I have been <a className="special-link clickable" href="https://www.youtube.com/@rafaelvasconcelosazevedo/videos" target="_blank" >playing the guitar</a> for over a decade and have performed live for hundreds of people.
                            </p>
                        </div>
                    </div>
                </div>
            </section>

            <section id="experience" className="py-5">
                <div className="container">
                    <div className="row">
                        <div className="col-md-8 offset-md-2">
                            <h2 className="section-title">Academic Experience</h2> 
                            <div className="mb-4">
                                <h4 className="experience-title">College</h4>
                                <p>2021 - Now</p>
                                <p>
                                    I first joined college in 2021, entering in my first option: Electrical Engineering.
                                    <br></br>
                                    After one year, though, I decided that wasn't what I was looking for and so I changed to Computer Sciences Engineering, the bachelors im currently taking.
                                </p>
                                <p>
                                    So far I've learned HTML, JavaScript, CSS, SQL, C++, C, Java, Python, Dart (Flutter) and, other than programming languages, Network Communications, Operating Systems, Data Structures, Usability, Object Oriented Programming, Databases and much more.
                                </p>
                            </div>
                            <div className="mb-4">
                                <h4 className="experience-title">Frontend Development</h4>
                                <p>2024</p>
                                <p>
                                    Developed a website similar to a blog/Reddit. This website, made for a company, was part of a class named Integrated Project.
                                    <br></br>
                                    Although no longer active, the source code for the project can still be found on my <a href="https://github.com/Ralxm/PINT" target="_blank" className="special-link clickable">GitHub</a>.
                                </p>
                            </div>
                            <div className="mb-4">
                                <h4 className="experience-title">High School</h4>
                                <p>2018 - 2021</p>
                                <p>
                                    My three year stay in high school was made in Escola Secundária Viriato, in Viseu, and, from the four normal courses available, I chose Sciences and Technologies.
                                </p>
                                <p>
                                    This course is focused on maths, physics & chemistry and has some optional classes like biology & geology and descritive geometry.
                                    During my stay I managed to achieve an average grade of 15.0 out of 20.0.
                                </p>
                            </div>
                            <div className="mb-4">
                                <h4 className="experience-title">National Computer Olympiads</h4>
                                <p>2021</p>
                                <p>
                                    The <a className="special-link clickable" href="https://apdsi.pt/iniciativa/olimpiadas-informatica/" target="_blank">National Computer Olympiads</a> is a national level contest, in Portugal, where high school students sign up for multiple coding challenges in programming languages like C++ or Java.
                                    <br></br>
                                    In 2021, during my final year of high school, I competed in the contest and achieved 22nd place nationwide.
                                </p>
                            </div>
                            <div className="mb-4">
                                <h4 className="experience-title">Youth Parliament</h4>
                                <p>2019</p>
                                <p>
                                    The Youth Parliament is a project created by the portuguese government to help promote and teach the democratic process of legislative elections to middle and high scool students. 
                                </p>
                                <p>
                                    In 2019 I participated in the debate phase to choose the table representatives for the district of Viseu.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            
            <section id="experience" className="py-5">
                <div className="container">
                    <div className="row">
                        <div className="col-md-8 offset-md-2">
                            <h2 className="section-title">Personal Experience</h2>
                            <div>
                                <h4 className="experience-title">Guitar</h4>
                                <p>2013 - Now</p>
                                <p>
                                    Like mentioned before, one of my passion is music. And not only do I enjoy listening to it, I also love playing.
                                    <br></br>
                                    I started learning how to play the guitar in 2013 with a simple classic guitar. Fast forward to 2014 I had already gotten a semi-acoustic and, in 2016, I bought my first electric guitar
                                </p>
                                <p>
                                    Being able to play the guitar has given me amazing experiences throughout the years. From playing in front of a few dozen people in middle school (2017) to performing for hundreds of people during my first and second year of high school (2018, 2019) and even making my <a className='special-link clickable' href='https://open.spotify.com/track/0FuQPmU6mVMNvKnidCcPyA?si=1d3fa261b9ea4b93' target="_blank">own song</a> (2020) with a friend and <a className='special-link clickable' href='https://www.youtube.com/live/edp7Wq4jRq8?si=b4c1z7vy-YSuzTGw&t=2372' target="_blank">performing it live</a>.
                                </p>
                                <p>
                                    My high school, Escola Secundária Viriato, had a music group with around 10 people. We would gather every wednesday to train and prepare for the next big thing.
                                </p>
                                <p>
                                    Now, without any band, group or project in mind, I keep playing and trying to improve my guitar skills and knowledge. One of my big references is the brazilian Mateus Asato, considered by many one of the best guitar players in the world.
                                </p>
                            </div>
                            <div>
                                <h4 className="experience-title">Guide / Volunteer</h4>
                                <p>2018</p>
                                <p>
                                    In 2018, during the final year of middle school, my home city, Viseu, hosted the <a href="https://desportoescolar.dge.mec.pt/" className="special-link clickable" target="_blank">National Youth School Sports Championships</a>.
                                    <br></br>
                                    As a guide / volunteer I helped guiding the students coming from all over the country in my home town. Not only was this a great oportunity to meet people of my age from other cities but also understand and realize how big events like these are managed.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section id="experience" className="py-5">
                <div className="container">
                    <div className="row">
                        <div className="col-md-8 offset-md-2">
                            <h2 className="section-title">Professional Experience</h2>
                            <div>
                                <h4 className="experience-title">To be made...</h4>
                                <p>???</p>

                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section id="skills" className="py-5">
                <div className="container">
                    <div className="row">
                        <div className="col-md-8 offset-md-2">
                            <h2 className="section-title">Skills</h2>
                            <ul className="list-unstyled">
                                <li><h5>Frontend Development</h5></li>
                                <li><h5>JavaScript, HTML, CSS, React</h5></li>
                                <li><h5>Backend Development</h5></li>
                                <li><h5>Sequelize, Express, PostgreSQL</h5></li>
                                <li><h5>Project Collaboration</h5></li>
                                <li><h5>Problem-Solving (Competitive Programming)</h5></li>
                                <li><h5>Java, C++ and C Programming</h5></li>
                                <li><h5>Event Coordination & Teamwork</h5></li>
                                <li><h5>Communication and Guiding Skills</h5></li>
                                <li><h5>Adaptability & Flexibility</h5></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>


            <footer className="footer text-center py-3">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 offset-md-3">
                            <p>Contact: rafael61111@gmail.com | pv22972@alunos.estgv.ipv.pt</p>
                            <p>&copy; 2024 Rafael Azevedo. All rights reserved.</p>
                        </div>
                    </div>
                </div>
            </footer>
        </div>
    )
}
